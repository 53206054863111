<template>
    <h-row>
        <h-col class="pa-4">

            <h-row justify="center">
                <h-col class="pr-sm-1" cols="12" sm="auto" no-padding>
                    <h1 class="text-center">{{ $store.getters.SECURE_WELCOME }}</h1>
                </h-col>
                <h-col class="pl-sm-1" cols="12" sm="auto" no-padding>
                    <h1 class="text-center">{{ utente.NOME }} {{ utente.COGNOME }}</h1>
                </h-col>
            </h-row>

            <h-row justify="center">
                <i class="caption">{{ $store.getters.SECURE_HINTS }}</i>
            </h-row>

        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'Secure',
        components: {HRow, HCol},
        computed: {
            utente: function () {
                return this.$store.state.authModule.utente;
            }
        }
    }
</script>

<style scoped>

</style>
